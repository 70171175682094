import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image';
import { kebabCase } from 'lodash';
import React from 'react';

import IntroText from '../components/intro-text';
import Layout from '../components/layout';
import MetaData from '../components/meta-data';
import NewsSection from '../components/news-section';

const NewsEntry = ({ data }) => {
  const entry = data.markdownRemark;

  const details = (
    <ul className="entry-meta mt-20 mb-0">
      <li className="entry-date">
        <i className="fa fa-calendar-o"></i>
        {entry.frontmatter.date}
      </li>
      <li className="entry-category">
        <i className="fa fa-folder-open"></i>
        <Link to={'/news/' + kebabCase(entry.frontmatter.category) + '/'}>
          {entry.frontmatter.category}
        </Link>
      </li>
    </ul>
  );

  return (
    <Layout path="/news/">
      <MetaData
        title={entry.frontmatter.title}
        description={entry.frontmatter.description}
        image={getSrc(entry.frontmatter.image)}
      />

      <IntroText title={entry.frontmatter.title} details={details}>
        {entry.frontmatter.description}
      </IntroText>

      <NewsSection>
        <article className="entry-item">
          <div className="entry-img">
            <GatsbyImage
              image={getImage(entry.frontmatter.image)}
              alt={entry.frontmatter.title}
            />
          </div>

          {entry.html && (
            <div className="entry-wrap">
              <div className="entry">
                <div
                  className="entry-content"
                  dangerouslySetInnerHTML={{ __html: entry.html }}
                />
              </div>
            </div>
          )}

          {entry.frontmatter.link && (
            <div className="entry-wrap">
              <div className="entry">
                <div className="entry-content">
                  <Link to={entry.frontmatter.link} className="read-more">
                    Mehr erfahren
                  </Link>
                </div>
              </div>
            </div>
          )}
        </article>
      </NewsSection>
    </Layout>
  );
};

export default NewsEntry;

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        date(formatString: "DD. MMMM YYYY", locale: "de")
        category
        link
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  }
`;
